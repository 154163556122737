import clsx from "clsx";
import { format } from "date-fns";
import { ReactElement, useState } from "react";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { Button } from "../../../components/Actions/Button";
import { ButtonCopy } from "../../../components/Actions/ButtonCopy";
import { Badge, BadgeColor } from "../../../components/Foundations/Badge";
import {
  IconCheckmarkCircleOutlined,
  IconChevronDown,
  IconChevronUp,
  IconRemoveCircleOutlined
} from "../../../components/Icons";
import { Icon } from "../../../components/Icons/Icon";
import { Card } from "../../../components/Layout/Card";
import styles from "./PricingMetadataCard.module.scss";

type FeePlan = components["schemas"]["FeePlan"];
type FeePlanAgreement = components["schemas"]["Agreement"];
type AgreementStatus = FeePlanAgreement["status"];
type PartnerPricingAgreement = components["schemas"]["PartnerPricingAgreement"];
function isPartnerPricingAgreement(
  agreement: FeePlanAgreement | PartnerPricingAgreement
): agreement is PartnerPricingAgreement {
  return "revenueShare" in agreement;
}

const statusToBadge: Record<AgreementStatus, { icon: ReactElement; color: BadgeColor }> = {
  active: { icon: <Icon iconComponent={IconCheckmarkCircleOutlined} />, color: "blue" },
  terminated: { icon: <Icon iconComponent={IconRemoveCircleOutlined} />, color: "gray" }
};

interface PricingMetadataCardProps {
  feePlan: FeePlan;
  feePlanAgreement?: FeePlanAgreement | PartnerPricingAgreement;
  className?: string;
}

export const PricingMetadataCard = ({
  feePlan,
  feePlanAgreement,
  className
}: PricingMetadataCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card className={clsx(styles.card, className)}>
      <div className={styles.topSection}>
        <div>
          <p className={styles.cardAcquiringModel}>
            {feePlan.cardAcquiringModel.replaceAll("-", " ").toUpperCase()}
          </p>
          <div className={styles.feePlanNameContainer}>
            <h2 className={styles.feePlanName}>{feePlan.name}</h2>
            {feePlanAgreement && (
              <Badge
                Icon={statusToBadge[feePlanAgreement.status].icon}
                color={statusToBadge[feePlanAgreement.status].color}
                data-testid={`agreement-status-badge-${feePlanAgreement.status}`}
                label="Agreement status"
                noLabel
              />
            )}
          </div>
        </div>
        <div className={styles.planIdContainer}>
          Plan ID{" "}
          <ButtonCopy
            className={styles.buttonCopy}
            copyContent={feePlan.planID}
            truncate="middle"
            iconPlacement="right"
          >
            {feePlan.planID}
          </ButtonCopy>
        </div>
      </div>
      <hr className={styles.hr} />
      <div>
        {isExpanded && (
          <>
            {feePlan.description && (
              <div className={styles.row}>
                <div className={styles.name}>Description</div>
                <div className={styles.right}>{feePlan.description}</div>
              </div>
            )}
            {feePlanAgreement && (
              <div className={styles.row}>
                <div className={styles.name}>Accepted on</div>
                <div className={styles.right}>
                  {format(feePlanAgreement.acceptedOn, "MMMM d, yyyy")}
                </div>
              </div>
            )}
            {feePlanAgreement && isPartnerPricingAgreement(feePlanAgreement) && (
              <div className={styles.row}>
                <div className={styles.name}>Partner revenue share</div>
                <div className={styles.right}>{feePlanAgreement.revenueShare}%</div>
              </div>
            )}
          </>
        )}
        <div className={styles.viewButtonContainer}>
          {isExpanded ? (
            <Button
              buttonType="secondary"
              buttonStyle="text"
              buttonSize="XS"
              onClick={() => setIsExpanded(false)}
            >
              View less
              <IconChevronUp />
            </Button>
          ) : (
            <Button
              buttonType="secondary"
              buttonStyle="text"
              buttonSize="XS"
              onClick={() => setIsExpanded(true)}
            >
              View more
              <IconChevronDown />
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};
