import { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLinkClickHandler } from "react-router";
import { BaseButton, Button, Icon, Nameplate } from "@moovfinancial/cargo";
import { IconAddCircleOutlined } from "@moovfinancial/cargo/icons";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import BankLogo from "components/bank-logo/BankLogo";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import AccountStatus from "pages/accounts/AccountDetail/overview/payment-methods/AccountStatus";
import { FooterButtons } from "../FooterButtons";
import { VerificationModal } from "./VerificationModal";
import styles from "./ReviewBankAccounts.module.scss";

type BankAccount = components["schemas"]["BankAccountResponse"];

interface BankAccountStepProps {
  bankAccounts: BankAccount[];
}

const AddRow = ({ isEmptyState = false }: { isEmptyState?: boolean }) => {
  // There won't be unsaved changes on this page, so we skip the unsaved changes check performed in OnboardingStepsContextProvider
  const handleClick = useLinkClickHandler<HTMLButtonElement>("./add", {
    state: { skipUnsavedChangesCheck: true }
  });

  return (
    <BaseButton className={styles.addRow} onClick={handleClick}>
      <Icon iconComponent={IconAddCircleOutlined} />
      {isEmptyState ? "Add a bank account" : "Add a different bank account"}
    </BaseButton>
  );
};

const NameplateRightContent = ({
  bankAccount,
  setSelectedBankAccount
}: {
  bankAccount: BankAccount;
  setSelectedBankAccount: (bankAccount: BankAccount) => void;
}) => {
  if (bankAccount.status === "pending") {
    return (
      <>
        <Button
          buttonSize="S"
          buttonType="secondary"
          buttonStyle="fill"
          type="button"
          onClick={() => setSelectedBankAccount(bankAccount)}
        >
          Verify
        </Button>
      </>
    );
  } else {
    return <AccountStatus noLabel={false} status={bankAccount.status} />;
  }
};

export function ReviewBankAccounts({ bankAccounts }: BankAccountStepProps) {
  const { getNextStepUrl, getPreviousStepUrl } = useContext(OnboardingStepsContext);
  // There won't be unsaved changes on this page, so we skip the unsaved changes check performed in OnboardingStepsContextProvider
  const handleBackClick = useLinkClickHandler<HTMLButtonElement>(getPreviousStepUrl(), {
    state: { skipUnsavedChangesCheck: true }
  });
  const handleContinueClick = useLinkClickHandler<HTMLButtonElement>(getNextStepUrl(), {
    state: { skipUnsavedChangesCheck: true }
  });
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Bank account</title>
      </Helmet>
      <h2 className={styles.header}>Bank account</h2>
      <div className={styles.list}>
        {bankAccounts.map((bankAccount: BankAccount) => (
          <Nameplate
            capitalize
            className={styles.nameplate}
            Icon={<BankLogo id={bankAccount.routingNumber} size={2.5} />}
            key={bankAccount.bankAccountID}
            rightContent={
              <NameplateRightContent
                bankAccount={bankAccount}
                setSelectedBankAccount={setSelectedBankAccount}
              />
            }
            subtitle={bankAccount.bankName}
            title={`${bankAccount.bankAccountType} ••••${bankAccount.lastFourAccountNumber}`}
          />
        ))}

        <AddRow />
      </div>
      <FooterButtons onBack={handleBackClick} onContinue={handleContinueClick} />
      {selectedBankAccount && (
        <VerificationModal
          bankAccount={selectedBankAccount}
          handleBackClick={() => setSelectedBankAccount(undefined)}
        />
      )}
    </div>
  );
}
