import type {
  DeepBooleanify,
  DeepNonNullable,
  DeepPartial,
  DeepRequired,
  DeepTypify
} from "@moovfinancial/common/types/DeepTypes";
import { Enum } from "@moovfinancial/common/types/Enum";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { MetaData } from "./MetaData.model";
import {
  AccountCapability,
  type CapabilityName,
  type CapabilityStatus
} from "./capabilities.model";

type BirthDate = components["schemas"]["BirthDate"];

export interface AccountSummary {
  accountId: string; // TODO: fix casing
  displayName: string;
  email: string;
  createdOn: string;
  type: string;
  enabled: boolean;
}

export type AccountType = "individual" | "business";

export type AccountMode = "production" | "sandbox";

export const businessTypeMap = {
  llc: "LLC",
  partnership: "Partnership",
  publicCorporation: "Public corporation",
  soleProprietorship: "Sole proprietorship",
  trust: "Trust",
  privateCorporation: "Private corporation",
  unincorporatedAssociation: "Unincorporated association",
  unincorporatedNonProfit: "Unincorporated non-profit",
  incorporatedNonProfit: "Incorporated non-profit",
  governmentEntity: "Government entity"
};

export const primaryRegulatorMap = {
  OCC: "OCC",
  FDIC: "FDIC",
  NCUA: "NCUA",
  FRB: "Federal Reserve Bank"
};

export type BusinessType = Enum<typeof businessTypeMap>;

export interface FacilitatorAccount {
  accountID: string;
  accountType: AccountType;
  connectedTo: string;
  connectionID: string;
  createdOn: string;
  disabledOn: string | null;
  displayName: string;
  email: string;
  enabled: boolean;
  updatedOn: string;
}

export interface Address {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateOrProvince?: string;
  postalCode?: string;
  country?: string;
}

export interface AddressSuggestion extends Address {
  entries?: number;
}

export interface Phone {
  number?: string;
  countryCode?: string;
}

export interface Name {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
}

export interface DateObject {
  day: number | undefined;
  month: number | undefined;
  year: number | undefined;
}

export interface SSN {
  full?: string;
  lastFour?: string;
}

export interface ITIN {
  full?: string;
  lastFour?: string;
}

export interface GovernmentID {
  ssn?: SSN | null;
  itin?: ITIN | null;
}

export interface Responsibilities {
  isController?: boolean;
  isOwner?: boolean;
  ownershipPercentage?: number;
  jobTitle?: string;
}

export interface Individual {
  name: Name;
  phone?: Phone;
  email: string;
  address?: Address;
  birthDateProvided?: boolean;
  governmentIDProvided?: boolean;
  birthDate?: BirthDate;
  governmentID?: GovernmentID;
}

export interface CreateIndividual {
  name: Name;
  phone?: Phone;
  email?: string;
  address?: Address;
  birthDate?: BirthDate;
  governmentID?: GovernmentID;
}

export interface PatchIndividual
  extends Omit<Partial<Individual>, "birthDateProvided" | "governmentIDProvided"> {}

export interface Representative {
  representativeID: string;
  name: Name;
  phone?: Phone;
  email?: string;
  address?: Address;
  birthDateProvided: boolean;
  birthDate?: BirthDate;
  governmentID?: GovernmentID;
  governmentIDProvided: boolean;
  responsibilities?: Responsibilities;
  createdOn: string;
  updatedOn: string;
  disabledOn?: string;
}

export interface PatchRepresentative extends Partial<Representative> {
  representativeID: string;
}

export interface CreateRepresentative extends DeepPartial<Representative> {
  name: Name;
}

export interface IndustryCodes {
  naics: string;
  sic: string;
  mcc: string;
}

export type PrimaryRegulator = "OCC" | "FDIC" | "NCUA" | "FRB";

export interface Business {
  legalBusinessName?: string;
  doingBusinessAs?: string;
  businessType: BusinessType;
  address: Address;
  phone: Phone;
  email: string;
  website?: string;
  description?: string;
  taxIDProvided?: boolean;
  representatives?: Representative[];
  ownersProvided?: boolean;
  industryCodes: IndustryCodes;
  taxID?: TaxID;
  primaryRegulator?: PrimaryRegulator;
}

export interface CreateBusiness {
  ownersProvided?: boolean;
  legalBusinessName: string;
  doingBusinessAs?: string;
  businessType?: BusinessType;
  address?: Address;
  phone?: Phone;
  email?: string;
  website?: string;
  description?: string;
  taxID?: TaxID;
  industryCodes?: IndustryCodes;
  primaryRegulator?: PrimaryRegulator;
}

export interface EIN {
  number: string;
}

export interface TaxID {
  ein: EIN;
}

export interface PatchBusiness
  extends Omit<Partial<Business>, "representatives" | "taxIDProvided"> {}

export interface PartialBusinessAccount {
  legalBusinessName?: string;
  businessType: BusinessType;
  website?: string;
  description?: string;
  mode: AccountMode;
}

export interface CustomerSupport {
  email?: string;
  phone?: Phone | null;
  website?: string;
  address?: Address;
}

export interface TermsOfService {
  acceptedDate: string;
  acceptedIP: string;
  token: string;
}

export interface PatchTermsOfService {
  token: string;
}

export interface AccountSettings {
  cardPayment?: {
    statementDescriptor: string;
  };
  achPayment?: {
    companyName: string;
  };
}

export type SimpleAccountVerificationStatus =
  | "unverified"
  | "pending"
  | "resubmit"
  | "review"
  | "verified"
  | "failed";

export interface Account {
  accountID: string;
  accountType: AccountType;
  displayName: string;
  capabilities?: AccountCapability[];
  customerSupport?: CustomerSupport;
  profile: {
    individual?: Individual;
    business?: Business;
  };
  metadata?: MetaData;
  termsOfService?: TermsOfService;
  verification?: {
    verificationStatus: SimpleAccountVerificationStatus;
  };
  settings?: AccountSettings;
  mode?: AccountMode;
  foreignID?: string;
  createdOn: string;
  updatedOn: string;
  disconnectedOn?: string;
}

export interface CreateAccount {
  accountType: AccountType;
  profile: {
    individual?: CreateIndividual;
    business?: CreateBusiness;
  };
  metadata?: MetaData;
  termsOfService?: PatchTermsOfService;
  foreignID?: string;
  customerSupport?: CustomerSupport;
  settings?: AccountSettings;
  capabilities?: string[];
}

export interface PatchAccount {
  profile?: {
    individual?: PatchIndividual;
    business?: PatchBusiness;
  };
  metadata?: MetaData;
  termsOfService?: PatchTermsOfService;
  foreignID?: string;
  customerSupport?: CustomerSupport;
  settings?: AccountSettings;
}

export interface UserAccount {
  accountID: string;
  accountMode?: AccountMode;
  displayName: string;
  superaccess?: boolean;
}

export type AccountStatusType = "enabled" | "disabled";

export interface AccountListFilter {
  skip?: number;
  count?: number;
  name?: string;
  email?: string;
  type?: "individual" | "business";
  foreignID?: string;
  verification_status?: SimpleAccountVerificationStatus;
  mode?: "production" | "sandbox";
}

export const defaultAccountListFilter = {
  skip: 0,
  count: 20
};
interface FacilitatorAccountSettings extends AccountSettings {
  onRepresentativeBypassList?: boolean;
}

export interface EnrichedFacilitatorAccount extends Account {
  settings: FacilitatorAccountSettings;
}

export interface DecryptedAccountData {
  governmentID?: GovernmentID;
  taxID?: TaxID;
  birthDate?: BirthDate;
}

export type DecryptedRepresentativeData = Omit<DecryptedAccountData, "taxID">;

/**
 *
 * 🔴 Below this line is code carried over from V1 Account Model, so probably very outdated and fragile
 *
 */

/** Mimics the structure of the Account object, but every field is an optional boolean */
export type BooleanAccountMap = DeepPartial<DeepBooleanify<DeepRequired<DeepNonNullable<Account>>>>;

/** Mimics the structure of the Representative object, but every field is an optional boolean */
export type ChangeRequiredRepresentativeMap = DeepPartial<
  DeepTypify<DeepRequired<DeepNonNullable<Representative>>, boolean | string>
>;

export interface SimpleCapability {
  capability: CapabilityName;
  status: CapabilityStatus;
}
