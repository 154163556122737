import clsx from "clsx";
import {
  type ChangeEvent,
  CompositionEvent,
  type FocusEvent,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  AddressAutoComplete,
  type AddressSuggestion,
  Alert,
  Button,
  FloatingLabelInput,
  FloatingLabelWrapper,
  FormGroup,
  type GovernmentID,
  GovernmentIDInput,
  PercentInput,
  PhoneNumberInput,
  Select
} from "@moovfinancial/cargo";
import {
  BirthDate,
  BirthDateMaskingInput
} from "@moovfinancial/cargo/src/components/BusinessLogicCoupled/Form/Input/MaskingInputs/BirthDateMaskingInput";
import useValidatedFields from "@moovfinancial/cargo/src/hooks/useValidatedFields";
import {
  armedForcesOptions,
  stateOptions,
  territoryOptions
} from "@moovfinancial/common/constants/state";
import { Representative } from "@moovfinancial/common/types/models/correctedTypes.model";
import { REGEX } from "@moovfinancial/common/utils/regex";
import { Checkbox } from "components/form/Form";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { OnboardingContext } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { ValidatedOwnerFields } from "contexts/OnboardingStepsContext.utils";
import { FooterButtons } from "../FooterButtons";
import { OnboardingErrors, handleError, parseErrors } from "../helpers/errors";
import { calculateAvailableOwnership } from "./Owners.utils";
import { OwnersStepHeading } from "./OwnersStepHeading";
import { RemoveOwnerConfirmation } from "./RemoveOwnerConfirmation";
import styles from "./AddOwner.module.scss";

export interface AddOwnerFormState {
  address: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
    country: string;
  };
  birthDate: BirthDate | undefined;
  birthDateProvided: boolean;
  email: string;
  firstName: string;
  governmentID?: GovernmentID;
  governmentIDProvided: boolean;
  isController: boolean;
  isOwner: boolean;
  jobTitle: string;
  lastName: string;
  ownershipPercentage: number;
  phone?: string;
  representativeID?: string;
}

export const DEFAULT_ADD_OWNER_FORM_STATE: AddOwnerFormState = {
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateOrProvince: "",
    postalCode: "",
    country: "US"
  },
  birthDate: undefined,
  birthDateProvided: false,
  email: "",
  firstName: "",
  governmentID: undefined,
  governmentIDProvided: false,
  isController: false,
  isOwner: false,
  jobTitle: "",
  lastName: "",
  ownershipPercentage: 0
};

const isAuthorizedRepresentativeFields: Partial<Record<keyof AddOwnerFormState, boolean>> = {
  firstName: true,
  lastName: true,
  isOwner: true,
  isController: true,
  representativeID: true
};

const isOwnerFields: Record<keyof AddOwnerFormState, boolean> = {
  address: true,
  birthDate: true,
  birthDateProvided: true,
  email: true,
  firstName: true,
  governmentID: true,
  governmentIDProvided: true,
  isController: true,
  isOwner: false,
  jobTitle: true,
  lastName: true,
  ownershipPercentage: true,
  phone: true,
  representativeID: true
};

const isControllerFields: Record<keyof AddOwnerFormState, boolean> = {
  address: true,
  birthDate: true,
  birthDateProvided: true,
  email: true,
  firstName: true,
  governmentID: true,
  governmentIDProvided: true,
  isController: true,
  // if we send this field as false when controller = true, API fails
  isOwner: false,
  jobTitle: true,
  lastName: true,
  ownershipPercentage: true,
  phone: true,
  representativeID: true
};

export const mapFormStateToRepresentative = (
  formState: AddOwnerFormState
): Partial<Representative> => {
  const isController = formState.isController;
  const isOwner = formState.isOwner;

  const shouldSendField = (fieldName: keyof AddOwnerFormState) => {
    const sendFieldBecauseController = isController && isControllerFields[fieldName];
    const sendFieldBecauseOwner = isOwner && isOwnerFields[fieldName];
    const sendFieldBecauseAuthorizedRepresentative = isAuthorizedRepresentativeFields[fieldName];
    const shouldSendField =
      sendFieldBecauseController ||
      sendFieldBecauseOwner ||
      sendFieldBecauseAuthorizedRepresentative;
    // We return undefined so we can short-circuit the return and avoid writing a ternary for every field
    return shouldSendField ? true : undefined;
  };

  return {
    address:
      shouldSendField("address") &&
      formState.address?.addressLine1 &&
      formState.address.city &&
      formState.address.postalCode &&
      formState.address.stateOrProvince
        ? {
            addressLine1: formState.address.addressLine1,
            addressLine2: formState.address.addressLine2,
            city: formState.address.city,
            country: formState.address.country ?? "US",
            postalCode: formState.address.postalCode,
            stateOrProvince: formState.address.stateOrProvince
          }
        : undefined,
    birthDate:
      shouldSendField("birthDate") &&
      formState.birthDate?.day != null &&
      formState.birthDate?.month != null &&
      formState.birthDate?.year != null
        ? {
            day: formState.birthDate.day,
            month: formState.birthDate.month,
            year: formState.birthDate.year
          }
        : undefined,
    email: shouldSendField("email") && formState.email,
    governmentID:
      (shouldSendField("governmentID") &&
        (formState.governmentID?.ssn?.full
          ? {
              ssn: {
                full: formState.governmentID.ssn.full
              }
            }
          : undefined)) ||
      (formState.governmentID?.itin?.full
        ? {
            itin: {
              full: formState.governmentID.itin.full
            }
          }
        : undefined),
    name: shouldSendField("firstName") &&
      shouldSendField("lastName") && {
        firstName: formState.firstName,
        lastName: formState.lastName
      },
    phone: shouldSendField("phone") && { number: formState.phone },
    representativeID: shouldSendField("representativeID") && formState.representativeID,
    responsibilities: {
      isController: formState.isController,
      // isOwner should be undefined when isController is true, or the API pukes
      isOwner: shouldSendField("isOwner") && formState.isOwner,
      jobTitle: shouldSendField("jobTitle") ? formState.jobTitle : "",
      ownershipPercentage: shouldSendField("ownershipPercentage")
        ? formState.ownershipPercentage
        : 0
    }
  };
};

export const mapRepresentativeToFormState = (
  representative: Representative | undefined
): AddOwnerFormState => ({
  address: representative?.address ?? {
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "US",
    postalCode: "",
    stateOrProvince: ""
  },
  birthDate: representative?.birthDate ?? undefined,
  birthDateProvided: representative?.birthDateProvided ?? false,
  email: representative?.email ?? "",
  firstName: representative?.name?.firstName ?? "",
  governmentID: representative?.governmentID ?? { itin: { full: "" }, ssn: { full: "" } },
  governmentIDProvided: representative?.governmentIDProvided ?? false,
  isController: representative?.responsibilities?.isController ?? false,
  isOwner: representative?.responsibilities?.isOwner ?? false,
  jobTitle: representative?.responsibilities?.jobTitle ?? "",
  lastName: representative?.name?.lastName ?? "",
  ownershipPercentage: representative?.responsibilities?.ownershipPercentage ?? 0,
  phone: representative?.phone?.number,
  representativeID: representative?.representativeID
});

const findRepresentative = (
  representatives: Representative[],
  representativeID: string | undefined
): Representative | undefined =>
  representativeID
    ? representatives.find((rep) => rep.representativeID === representativeID)
    : undefined;

export const AddOwner = () => {
  const { facilitatorID } = useContext(FacilitatorContext);
  const { createRepresentative, patchRepresentative, representatives } =
    useContext(OnboardingContext);
  const { getPreviousStepUrl, renderFormValidated, setRenderFormValidated } =
    useContext(OnboardingStepsContext);
  const { representativeID } = useParams();

  const { formState, formFields, representative, setFormState, setRepresentative } =
    useContext(OnboardingStepsContext).ownersStep;
  const { fields } = useValidatedFields(formFields);

  const [apiErrors, setApiErrors] = useState<OnboardingErrors<Representative>>({});
  const [isAuthorized, setIsAuthorized] = useState(
    !formState.isController && !formState.isOwner && !!formState.representativeID
  );
  const [showOwnerOrControllerWarning, setShowOwnerOrControllerWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemovingRepresentative, setIsRemovingRepresentative] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (renderFormValidated) {
      fields.validate(formFields);
      setRenderFormValidated(false);
    }
  }, [fields, formFields, renderFormValidated, setRenderFormValidated]);

  useEffect(() => {
    // Because the representative ID is not available in the OnboardingStepsContext, we set the representative here
    const representativeBeingEdited = findRepresentative(representatives, representativeID);
    setRepresentative(representativeBeingEdited);

    // On unmount, set the form state and representative in OnboardingStepsContext back to their default values so we have a clean state when the component is mounted again
    return () => {
      setRepresentative(undefined);
      setFormState(mapRepresentativeToFormState(representativeBeingEdited));
    };
  }, [representativeID, representatives, setFormState, setRepresentative]);

  const availableOwnershipPercentage = useMemo(
    () => calculateAvailableOwnership(representatives, formState.representativeID),
    [representatives, formState.representativeID]
  );

  const handleIsControllerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({
      ...prev,
      isController: e.target.checked
    }));
    setShowOwnerOrControllerWarning(false);
  };

  const handleIsOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({
      ...prev,
      isOwner: e.target.checked
    }));
    setShowOwnerOrControllerWarning(false);
  };

  const handleIsAuthorizedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAuthorized(e.target.checked);
    setShowOwnerOrControllerWarning(false);
  };

  const handleInputValidation = (fieldName: ValidatedOwnerFields) => {
    if (fields.isInvalid(fieldName)) {
      fields.validate({
        [fieldName]: {
          basicValidation: false
        }
      });
    }
  };

  const handlePercentageChange = (value: number) => {
    // Most fields validate on blur (configured in the JSX below).
    // Once a field is invalid, we re-validate input on each keystroke.
    handleInputValidation("responsibilities.ownershipPercentage");
    setFormState((prev) => ({
      ...prev,
      ownershipPercentage: value
    }));
  };

  const handleGovernmentIDChange = (id: GovernmentID) => {
    // Most fields validate on blur (configured in the JSX below).
    // Once a field is invalid, we re-validate input on each keystroke.
    handleInputValidation("governmentID");
    setFormState((prev) => ({ ...prev, governmentID: id }));
  };

  const handleBirthDateChange = (date: BirthDate | undefined) => {
    // Most fields validate on blur (configured in the JSX below).
    // Once a field is invalid, we re-validate input on each keystroke.
    handleInputValidation("birthDate");
    if (!date) return;
    setFormState((prev) => ({ ...prev, birthDate: date }));
  };

  const handlePhoneChange = (phone: string) => {
    // Most fields validate on blur (configured in the JSX below).
    // Once a field is invalid, we re-validate input on each keystroke.
    handleInputValidation("phone.number");
    setFormState((prev) => ({ ...prev, phone }));
  };

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputValidation("name.firstName");
    setFormState((prev) => ({ ...prev, firstName: e.target.value }));
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputValidation("name.lastName");
    setFormState((prev) => ({ ...prev, lastName: e.target.value }));
  };

  const handleAddressLineOneChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputValidation("address.addressLine1");
    setFormState((prev) => ({
      ...prev,
      address: { ...prev.address, addressLine1: e.target.value }
    }));
  };

  const handleAddressSuggestionSelected = async (suggestion: AddressSuggestion) => {
    await new Promise<void>((resolve) => {
      setFormState((prev) => {
        const newState = {
          ...prev,
          address: {
            ...prev.address,
            addressLine1: suggestion.addressLine1 ?? prev.address?.addressLine1,
            addressLine2: suggestion.addressLine2 ?? "",
            city: suggestion.city ?? prev.address?.city,
            postalCode: suggestion.postalCode ?? prev.address?.postalCode,
            stateOrProvince: suggestion.stateOrProvince ?? prev.address?.stateOrProvince
          }
        };
        resolve();
        return newState;
      });
    });

    // Validate after state is updated
    fields.validate(formFields);
  };

  const handleCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputValidation("address.city");
    setFormState((prev) => ({
      ...prev,
      address: { ...prev.address, city: e.target.value }
    }));
  };

  const handleStateOrProvinceChange = (e: ChangeEvent<HTMLSelectElement>) => {
    handleInputValidation("address.stateOrProvince");
    setFormState((prev) => ({
      ...prev,
      address: { ...prev.address, stateOrProvince: e.target.value }
    }));
  };

  const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputValidation("address.postalCode");
    setFormState((prev) => ({
      ...prev,
      address: { ...prev.address, postalCode: e.target.value }
    }));
  };

  const handleFieldBlur = (event: FocusEvent<HTMLInputElement>) => {
    const fieldName = fields.assertFieldName(event.currentTarget.name);
    fields.validate(fieldName, event.currentTarget);
  };

  const handleJobTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputValidation("responsibilities.jobTitle");
    setFormState((prev) => ({ ...prev, jobTitle: e.target.value }));
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputValidation("email");
    setFormState((prev) => ({ ...prev, email: e.target.value }));
  };

  const handleContinue = async () => {
    setIsLoading(true);
    const { isInvalid } = fields.validate(formFields);

    // Return early if no authorization box is checked
    if (!formState.isController && !formState.isOwner && !isAuthorized) {
      setShowOwnerOrControllerWarning(true);
      setIsLoading(false);
      return;
    }

    // Return early if any fields are invalid
    const formIsInvalid = isInvalid();
    if (formIsInvalid) {
      setIsLoading(false);
      return;
    }

    // Submit form data to API
    let result;
    if (formState.representativeID) {
      result = await patchRepresentative(mapFormStateToRepresentative(formState));
    } else {
      result = await createRepresentative(mapFormStateToRepresentative(formState));
    }
    // Return early if API call fails
    const { data, response, error } = result;
    if (!data || error || !response.ok) {
      toast("There was an error saving the data. Please try again.");
      if (error) {
        // @ts-expect-error - TODO: openApiSpecIsWrong - I'm pretty sure error is an object, not a string 😫
        setApiErrors(parseErrors<Representative>(error));
      }
      setIsLoading(false);
      return;
    }

    // Navigate back to representative list
    void navigate("..", { state: { skipUnsavedChangesCheck: true } });
  };

  const hasRepresentatives = representatives.length > 0;
  const isFirstRep =
    !hasRepresentatives ||
    representatives.findIndex((r) => r.representativeID === representativeID) === 0;

  return (
    <div className={styles.content}>
      <div className={styles.layout}>
        <OwnersStepHeading
          subHeader={
            !hasRepresentatives
              ? "Tell us about yourself and confirm you're authorized to enter into an agreement on behalf of your company."
              : undefined
          }
        />
        <FormGroup noGap={false} noMargins>
          <div className={styles.twoColumns}>
            <FloatingLabelInput
              error={handleError(apiErrors.name?.firstName)}
              label="First name"
              minLength={1}
              name="name.firstName"
              onBlur={handleFieldBlur}
              onChange={handleFirstNameChange}
              pattern="[A-Za-z]+"
              value={formState.firstName}
              warning={fields.getMessageIfInvalid("name.firstName")}
              required
            />
            <FloatingLabelInput
              error={handleError(apiErrors.name?.lastName)}
              label="Last name"
              minLength={1}
              name="name.lastName"
              onBlur={handleFieldBlur}
              onChange={handleLastNameChange}
              pattern="[A-Za-z]+"
              value={formState.lastName}
              warning={fields.getMessageIfInvalid("name.lastName")}
              required
            />
          </div>
          <Checkbox
            checked={formState.isOwner}
            label={`${isFirstRep ? "I own" : "They are an owner with"} at least 25% stake in the company.`}
            name="isOwner"
            onChange={handleIsOwnerChange}
          />
          <Checkbox
            checked={formState.isController}
            label={`${isFirstRep ? "I am" : "They are"} a control officer with significant management authority.`}
            name="isController"
            onChange={handleIsControllerChange}
          />
          {isFirstRep && (
            <Checkbox
              checked={isAuthorized}
              label="I am an authorized representative/signatory."
              name="isAuthorized"
              onChange={handleIsAuthorizedChange}
            />
          )}
          {showOwnerOrControllerWarning && (
            <Alert
              type="warning"
              label="One of the statements above must be checked off in order to continue."
            />
          )}
          {formState.isOwner && (
            <FloatingLabelWrapper
              as={PercentInput}
              className={styles.input}
              error={handleError(apiErrors.responsibilities?.ownershipPercentage)}
              label="Percent ownership"
              maxOnChange={availableOwnershipPercentage}
              minLength={1}
              name="responsibilities.ownershipPercentage"
              onBlur={handleFieldBlur}
              onValueChange={handlePercentageChange}
              required
              value={formState.ownershipPercentage}
              warning={fields.getMessageIfInvalid("responsibilities.ownershipPercentage")}
            />
          )}
          {formState.isController && (
            <FloatingLabelInput
              label="Job title"
              name="responsibilities.jobTitle"
              onBlur={handleFieldBlur}
              onChange={handleJobTitleChange}
              value={formState.jobTitle}
              warning={fields.getMessageIfInvalid("responsibilities.jobTitle")}
              error={handleError(apiErrors.responsibilities?.jobTitle)}
              required
              minLength={1}
            />
          )}
          {(formState.isOwner || formState.isController) && (
            <>
              <GovernmentIDInput
                className={clsx(styles.noMargins, styles.input)}
                forceFloating={formState.governmentIDProvided}
                name="governmentID"
                onChange={handleGovernmentIDChange}
                placeholder={formState.governmentIDProvided ? "•••-••-••••" : undefined}
                value={formState.governmentID}
                warning={fields.getMessageIfInvalid("governmentID")}
                error={handleError(
                  apiErrors.governmentID?.itin?.full ||
                    apiErrors.governmentID?.itin?.lastFour ||
                    apiErrors.governmentID?.ssn?.full ||
                    apiErrors.governmentID?.ssn?.lastFour
                )}
                required={!formState.governmentIDProvided}
              />
              <FloatingLabelInput
                as={BirthDateMaskingInput}
                className={clsx(styles.noMargins)}
                label="Date of birth"
                name="birthDate"
                onValueChange={handleBirthDateChange}
                forceFloating={formState.birthDateProvided}
                placeholder={formState.birthDateProvided ? "••/••/••••" : "MM/DD/YYYY"}
                // @ts-expect-error - type of value is invalid for FloatingLabelInput, but it's valid for BirthDateMaskingInput
                value={formState.birthDate ?? undefined}
                warning={fields.getMessageIfInvalid("birthDate")}
                error={handleError(
                  apiErrors.birthDate?.day ||
                    apiErrors.birthDate?.month ||
                    apiErrors.birthDate?.year
                )}
              />
              <FloatingLabelWrapper
                as={PhoneNumberInput}
                name="phone.number"
                label="Phone number"
                className={styles.input}
                onValueChange={handlePhoneChange}
                value={formState.phone ?? ""}
                warning={fields.getMessageIfInvalid("phone.number")}
                error={handleError(apiErrors.phone?.number || apiErrors.phone?.countryCode)}
                minLength={1}
                required
              />
              <FloatingLabelInput
                autoComplete="email work"
                label="Email"
                name="email"
                onBlur={handleFieldBlur}
                onChange={handleEmailChange}
                pattern={REGEX.EMAIL}
                type="email"
                value={formState.email ?? ""}
                warning={fields.getMessageIfInvalid("email")}
                error={handleError(apiErrors.email)}
              />
              <FormGroup noGap={false}>
                <FloatingLabelWrapper
                  as={AddressAutoComplete}
                  autoComplete="none"
                  error={handleError(apiErrors.address?.addressLine1)}
                  facilitatorID={facilitatorID}
                  label="Personal street address"
                  name="address.addressLine1"
                  onChange={handleAddressLineOneChange}
                  onSuggestionSelected={handleAddressSuggestionSelected}
                  value={formState.address?.addressLine1}
                  warning={fields.getMessageIfInvalid("address.addressLine1")}
                />
                <FloatingLabelInput
                  label="Apartment, suite, or floor"
                  name="address.addressLine2"
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      address: { ...prev.address, addressLine2: e.target.value }
                    }))
                  }
                  value={formState.address.addressLine2}
                  error={handleError(apiErrors.address?.addressLine2)}
                />
                <FloatingLabelInput
                  error={handleError(apiErrors.address?.city)}
                  label="City"
                  name="address.city"
                  onChange={handleCityChange}
                  value={formState.address.city}
                  warning={fields.getMessageIfInvalid("address.city")}
                />
                <div className={styles.twoColumns}>
                  <Select
                    className={styles.stateSelect}
                    error={handleError(apiErrors.address?.stateOrProvince)}
                    floatingLabelStyle
                    label="State"
                    name="address.stateOrProvince"
                    onChange={handleStateOrProvinceChange}
                    placeholder="--"
                    value={formState.address.stateOrProvince}
                    warning={fields.getMessageIfInvalid("address.stateOrProvince")}
                  >
                    {stateOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    <optgroup label="US outlying territories">
                      {territoryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Armed Forces">
                      {armedForcesOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </optgroup>
                  </Select>
                  <FloatingLabelInput
                    inputMode="numeric"
                    label="Zip code"
                    maxLength={5}
                    minLength={5}
                    name="address.postalCode"
                    error={handleError(apiErrors.address?.postalCode)}
                    onBeforeInput={(e: CompositionEvent<HTMLInputElement>) => {
                      if (e.data.match(/[^0-9]/)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={handlePostalCodeChange}
                    pattern="[0-9]{5}"
                    type="text"
                    value={formState.address.postalCode}
                    warning={fields.getMessageIfInvalid("address.postalCode")}
                  />
                </div>
              </FormGroup>
            </>
          )}
          {representative && (
            <Button
              buttonType="destructive"
              buttonStyle="text"
              onClick={() => setIsRemovingRepresentative(true)}
              fullWidth
            >
              Remove profile
            </Button>
          )}
          {isRemovingRepresentative && representative && (
            <RemoveOwnerConfirmation
              onRemove={() => {
                void navigate("..");
              }}
              onCancel={() => {
                setIsRemovingRepresentative(false);
              }}
              representative={representative}
            />
          )}
        </FormGroup>
        <FooterButtons
          isLoading={isLoading}
          onBack={() => (hasRepresentatives ? navigate("..") : navigate(getPreviousStepUrl()))}
          onContinue={handleContinue}
        />
      </div>
    </div>
  );
};
