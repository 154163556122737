import { useContext } from "react";
import { Action, MoovAdminResource, Resource } from "api/Role.model";
import { AccountContext } from "contexts/AccountContext";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { UserContext } from "contexts/UserContext";

/** Determines whether or not the user has access to the requested `resource.action`,
 * based on their member role, the account they are viewing, and their moov-admin status.
 */
export default function useRbac(action: Action, resource: Resource | MoovAdminResource): boolean {
  const { user, userCan, isSuper } = useContext(UserContext);
  const { facilitatorID } = useContext(FacilitatorContext);
  const { account } = useContext(AccountContext);

  // If user hasn't logged in yet, they can do anything
  // This is safe, because the user can't see any dashboard pages until they've logged in
  // It also simplifies our unit testing, because we don't have to mock a user on every test
  if (!user) {
    return true;
  }
  // "Super users" can do anything (this means the user is operating as a "moov-admin", and they have the /moov/support.read permission)
  // TODO FE-1298: I hate this variable name. Every Moov-admin has different levels of access. Doesn't make sense to call everyone with support.read a "super user".
  // TODO FE-1298: This check makes useRbac() useless for checks against a MoovAdminResource.
  if (isSuper) {
    return true;
  }
  // While viewing a **connected** account, the user can take actions as long as they have the "connections.<action>" permission
  if (account?.accountID && facilitatorID && account.accountID !== facilitatorID) {
    return userCan(action, Resource.Connections);
  }
  // Check if user has access to the requested `resource.action` (based on their member role)
  return userCan(action, resource);
}
