import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Application } from "api/Application.model";
import * as ApplicationsApi from "api/Applications";
import * as dashboardAPI from "api/Dashboard";
import { Capability, EnrichedFacilitatorAccount } from "api/v2";
import { Account } from "api/v2/accounts.model";
import { hasBeenSubmitted } from "helpers/dashboard";
import { APIContext } from "./APIContext";
import { UserContext } from "./UserContext";

interface ContextProps {
  children: React.ReactNode;
}

export interface FacilitatorContextType {
  /** The "active" facilitator. Either the account displayed in the AccountSelector, or (for moov admins) facilitator of the active connected account */
  facilitatorAccount: EnrichedFacilitatorAccount | null;
  /** ID of the active facilitator. Available asap, even before the full facilitatorAccount is loaded */
  facilitatorID: string;
  /** The sandbox/production counterpart to the active facilitator */
  associatedFacilitatorAccount: Account | null;
  /** Fetches a facilitator with the given id and sets it as the facilitatorAccount */
  setFacilitatorByID: (id: string) => Promise<void>;
  /** The mode of the active facilitatorAccount */
  mode: "sandbox" | "production" | "";
  /** The application of the active facilitator */
  activeApplication: Application | null;
  /** ID of the active application */
  activeApplicationID: string;
  /** True if the active facilitator has the "production-app" capability enabled. While loading, this value is undefined */
  productionAppEnabled: boolean | undefined;
  /** True if the active facilitator has requested "production-app" capability. While loading, this value is undefined */
  productionAppRequested: boolean | undefined;
  /** True if the facilitator was created after March 2, 2022 */
  facilitatorCreatedAfterCutoff: boolean;
  /** Whether or not the facilitator has submitted the production access form. While loading, this value is undefined */
  facilitatorSubmitted: boolean | undefined;
  /** Capabilities of the active facilitator */
  facilitatorCapabilities: Capability[];
  /** Reloads the active facilitator from the API */
  refreshFacilitatorAccount: () => void;
  /** True if the active account is a partner as opposed to a connected account. This is an alias for productionAppRequested. While loading, this value is undefined */
  isPartnerAccount: boolean | undefined;
}

/** A single source of truth for the "active" facilitator.
 * For most users, the "active" facilitator is always the account displayed in the AccountSelector.
 * On moov admin pages, the "active" facilitator is based on the currently displayed, connected account. */
export const FacilitatorContext = createContext<FacilitatorContextType>({
  activeApplication: null,
  activeApplicationID: "",
  associatedFacilitatorAccount: null,
  facilitatorAccount: null,
  facilitatorCapabilities: [],
  facilitatorCreatedAfterCutoff: false,
  facilitatorID: "",
  facilitatorSubmitted: false,
  mode: "",
  productionAppEnabled: false,
  productionAppRequested: false,
  refreshFacilitatorAccount: () => {},
  setFacilitatorByID: () => Promise.resolve(),
  isPartnerAccount: false
});

export default function FacilitatorContextProvider({ children }: ContextProps) {
  // Context
  const { moov } = useContext(APIContext);
  const { user, userAccounts, activeUserAccountID, setActiveUserAccountID } =
    useContext(UserContext);

  // State
  const [activeApplication, setActiveApplication] = useState<Application | null>(null);
  const [associatedFacilitatorAccount, setAssociatedFacilitatorAccount] = useState<Account | null>(
    null
  );
  const [facilitatorID, setFacilitatorID] = useState<string>("");
  const [facilitatorAccount, setFacilitatorAccount] = useState<EnrichedFacilitatorAccount | null>(
    null
  );
  const [facilitatorCapabilities, setFacilitatorCapabilities] = useState<Capability[]>([]);
  const [facilitatorCreatedAfterCutoff, setFacilitatorCreatedAfterCutoff] = useState(false);
  const [facilitatorSubmitted, setFacilitatorSubmitted] = useState<boolean | undefined>(undefined);
  const [mode, setMode] = useState<"sandbox" | "production" | "">("");
  const [productionAppEnabled, setProductionAppEnabled] = useState<boolean | undefined>(undefined);
  const [productionAppRequested, setProductionAppRequested] = useState<boolean | undefined>(
    undefined
  );

  // Load the complete facilitator account
  const setFacilitatorByID = useCallback(
    async (id: string) => {
      if (id === facilitatorID) return;
      setFacilitatorID(id);
      // Reset facilitator account state
      setFacilitatorAccount(null);
      setAssociatedFacilitatorAccount(null);
      setMode("");
      setActiveApplication(null);
      setFacilitatorCapabilities([]);
      setProductionAppEnabled(undefined);
      setProductionAppRequested(undefined);
      setFacilitatorCreatedAfterCutoff(false);
      setFacilitatorSubmitted(undefined);
      if (!id) return;
      // Fetch full facilitator account
      const [result, err] = await moov.accounts.getFacilitator(id);

      if (err) {
        toast("Error loading facilitator account");
        setFacilitatorAccount(null);
        return;
      }

      setFacilitatorAccount(result || null);
      setMode(result?.mode || "production");
    },
    [user, userAccounts, facilitatorID]
  );

  // If active UserAccount changes (from AccountSelector or routing history), update the facilitator
  useEffect(() => {
    if (
      activeUserAccountID !== "moov-admin" &&
      activeUserAccountID !== facilitatorAccount?.accountID
    ) {
      void setFacilitatorByID(activeUserAccountID);
    }
    if (activeUserAccountID === "moov-admin") {
      void setFacilitatorByID("");
    }
    if (activeUserAccountID === "") {
      void setFacilitatorByID("");
    }
  }, [activeUserAccountID, userAccounts]);

  // Load the facilitator account's associated test/prod account
  useEffect(() => {
    if (facilitatorAccount && userAccounts) {
      const otherMode = facilitatorAccount.mode === "sandbox" ? "production" : "sandbox";
      const matchingAccount = userAccounts
        .filter((a) => a.accountMode === otherMode)
        .find(
          (a) =>
            a.displayName === facilitatorAccount.displayName ||
            a.displayName === `[TEST] ${facilitatorAccount.displayName}`
        );
      if (matchingAccount) {
        void moov.accounts
          .get(matchingAccount.accountID, matchingAccount.accountID)
          .then(([result, error]) => {
            if (error) {
              toast("Unable to fetch associated facilitator account");
              setAssociatedFacilitatorAccount(null);
            } else if (result) {
              setAssociatedFacilitatorAccount(result);
            }
          });
        return;
      }
    }
    setAssociatedFacilitatorAccount(null);
  }, [facilitatorAccount, userAccounts]);

  // Determine if the facilitator has submitted the production access form
  useEffect(() => {
    if (!facilitatorAccount) {
      setFacilitatorSubmitted(undefined);
      return;
    }
    // The account model has a slim capability list model, check it immediately for production-app
    const hasProductionAppRequested = facilitatorAccount.capabilities?.find((capability) => {
      return capability.capability === "production-app";
    });
    if (!hasProductionAppRequested) {
      setFacilitatorSubmitted(false);
      setProductionAppRequested(false);
    } else {
      setProductionAppRequested(true);
      dashboardAPI
        .getOnboardingState(facilitatorAccount.accountID)
        .then((state) => {
          setFacilitatorSubmitted(hasBeenSubmitted(state.state));
        })
        .catch((_err: unknown) => {
          setFacilitatorSubmitted(false);
        });
    }
  }, [facilitatorAccount]);

  const prodCapabilityEnabled = (capabilities: Capability[]): boolean => {
    if (!Array.isArray(capabilities)) return false;
    return !!capabilities.find(
      (capability) => capability.capability === "production-app" && capability.status === "enabled"
    );
  };

  const refreshFacilitatorCapabilities = useCallback(() => {
    if (facilitatorAccount) {
      moov.capabilities
        .list(facilitatorAccount.accountID, facilitatorAccount.accountID)
        .then(([results]) => {
          setFacilitatorCapabilities(results ?? []);
          setProductionAppEnabled(prodCapabilityEnabled(results ?? []));
        })
        .catch((_err: unknown) => {
          setFacilitatorCapabilities([]);
          setProductionAppEnabled(false);
        });
    } else {
      setFacilitatorCapabilities([]);
    }
  }, [facilitatorAccount]);

  useEffect(() => refreshFacilitatorCapabilities(), [facilitatorAccount]);

  const refreshFacilitatorAccount = useCallback(() => {
    if (!facilitatorAccount?.accountID) return;
    void moov.accounts.getFacilitator(facilitatorAccount?.accountID).then(([result, err]) => {
      if (err) {
        toast("Error loading facilitator account");
        setFacilitatorAccount(null);
        return;
      }
      setFacilitatorAccount(result || null);
      setMode(result?.mode || "production");
    });
  }, [facilitatorAccount]);

  // Determine if the account was created prior to the cutoff date for some key features
  useEffect(() => {
    if (facilitatorAccount) {
      const dateCutoff = new Date("3/2/2022");
      const facilitatorCreatedDate = new Date(facilitatorAccount.createdOn);
      setFacilitatorCreatedAfterCutoff(facilitatorCreatedDate >= dateCutoff);
    }
  }, [facilitatorAccount]);

  // Update the account in the AccountSelector
  useEffect(() => {
    if (
      facilitatorAccount?.accountID &&
      activeUserAccountID !== facilitatorAccount?.accountID &&
      activeUserAccountID !== "moov-admin"
    ) {
      setActiveUserAccountID(facilitatorAccount?.accountID);
    }
  }, [facilitatorAccount]);

  // Get active application
  useEffect(() => {
    if (facilitatorAccount?.accountID) {
      ApplicationsApi.listApplications(facilitatorAccount.accountID)
        .then((applications) => (applications?.length ? applications[0] : undefined))
        .then((application: Application | void) => setActiveApplication(application || null))
        // TODO: @gamell to add otel logging
        .catch((_err: Response) => {});
    } else {
      setActiveApplication(null);
    }
  }, [facilitatorAccount?.accountID]);

  return (
    <FacilitatorContext.Provider
      value={{
        activeApplication,
        activeApplicationID: activeApplication?.applicationID || "",
        associatedFacilitatorAccount,
        facilitatorAccount,
        facilitatorCapabilities,
        facilitatorCreatedAfterCutoff,
        facilitatorID,
        facilitatorSubmitted,
        mode,
        productionAppEnabled,
        productionAppRequested,
        refreshFacilitatorAccount,
        setFacilitatorByID,
        isPartnerAccount: productionAppRequested
      }}
    >
      {children}
    </FacilitatorContext.Provider>
  );
}
