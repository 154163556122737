import clsx from "clsx";
import { CompositionEvent, FormEventHandler, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLinkClickHandler, useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  AddressAutoComplete,
  type AddressSuggestion,
  FloatingLabelInput,
  FloatingLabelWrapper,
  FormGroup,
  Icon,
  Select
} from "@moovfinancial/cargo";
import { IconTechnologyAndDataAi } from "@moovfinancial/cargo/icons";
import useValidatedFields from "@moovfinancial/cargo/src/hooks/useValidatedFields";
import {
  armedForcesOptions,
  stateOptions,
  territoryOptions
} from "@moovfinancial/common/constants/state";
import {
  type SparsePath,
  ValueAtPath,
  setSparsePath
} from "@moovfinancial/common/utils/setSparsePath";
import { Checkbox } from "components/form/Form";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { type Account, OnboardingContext, type PatchAccount } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { ValidatedCompanyAddressFields } from "contexts/OnboardingStepsContext.utils";
import { FooterButtons } from "../FooterButtons";
import { OnboardingErrors, handleError, parseErrors } from "../helpers/errors";
import styles from "./CompanyAddress.module.scss";

export interface CompanyAddressFormState {
  profile: {
    business: {
      address: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        stateOrProvince: string;
        postalCode: string;
        country: string;
      };
    };
  };
  customerSupport: {
    address: {
      addressLine1: string;
      addressLine2: string;
      city: string;
      stateOrProvince: string;
      postalCode: string;
      country: string;
    };
  };
  isSameSupportAddress: boolean;
}

export const accountAddressesMatch = (account: Account) => {
  const businessAddress = account.profile?.business?.address;
  const customerSupportAddress = account.customerSupport?.address;

  if (!businessAddress || !customerSupportAddress) return false;

  return (
    !!businessAddress.addressLine1 &&
    !!customerSupportAddress.addressLine1 &&
    businessAddress.addressLine1.trim().toLocaleLowerCase() ===
      customerSupportAddress.addressLine1.trim().toLocaleLowerCase() &&
    (businessAddress.addressLine2 ?? "").trim().toLocaleLowerCase() ===
      (customerSupportAddress.addressLine2 ?? "").trim().toLocaleLowerCase() &&
    businessAddress.city.trim().toLocaleLowerCase() ===
      customerSupportAddress.city.trim().toLocaleLowerCase() &&
    businessAddress.stateOrProvince.trim().toLocaleLowerCase() ===
      customerSupportAddress.stateOrProvince.trim().toLocaleLowerCase() &&
    businessAddress.postalCode.trim().toLocaleLowerCase() ===
      customerSupportAddress.postalCode.trim().toLocaleLowerCase()
  );
};

export const mapAccountToAddressFormState = (
  account: Partial<Account>
): CompanyAddressFormState => ({
  profile: {
    business: {
      address: {
        addressLine1: account.profile?.business?.address?.addressLine1 ?? "",
        addressLine2: account.profile?.business?.address?.addressLine2 ?? "",
        city: account.profile?.business?.address?.city ?? "",
        stateOrProvince: account.profile?.business?.address?.stateOrProvince ?? "",
        postalCode: account.profile?.business?.address?.postalCode ?? "",
        country: account.profile?.business?.address?.country ?? "US"
      }
    }
  },
  customerSupport: {
    address: {
      addressLine1: account.customerSupport?.address?.addressLine1 ?? "",
      addressLine2: account.customerSupport?.address?.addressLine2 ?? "",
      city: account.customerSupport?.address?.city ?? "",
      stateOrProvince: account.customerSupport?.address?.stateOrProvince ?? "",
      postalCode: account.customerSupport?.address?.postalCode ?? "",
      country: account.customerSupport?.address?.country ?? "US"
    }
  },
  isSameSupportAddress: accountAddressesMatch(account)
});

export const mapAddressFormStateToAccount = (
  formState: CompanyAddressFormState,
  isSameSupportAddress: boolean
): PatchAccount => ({
  profile: {
    business: {
      address: {
        addressLine1: formState.profile.business.address.addressLine1,
        addressLine2: formState.profile.business.address.addressLine2,
        city: formState.profile.business.address.city,
        stateOrProvince: formState.profile.business.address.stateOrProvince,
        postalCode: formState.profile.business.address.postalCode,
        country: formState.profile.business.address.country
      }
    }
  },
  customerSupport: {
    address: {
      addressLine1: isSameSupportAddress
        ? formState.profile.business.address.addressLine1
        : formState.customerSupport.address.addressLine1,
      addressLine2: isSameSupportAddress
        ? formState.profile.business.address.addressLine2
        : formState.customerSupport.address.addressLine2,
      city: isSameSupportAddress
        ? formState.profile.business.address.city
        : formState.customerSupport.address.city,
      stateOrProvince: isSameSupportAddress
        ? formState.profile.business.address.stateOrProvince
        : formState.customerSupport.address.stateOrProvince,
      postalCode: isSameSupportAddress
        ? formState.profile.business.address.postalCode
        : formState.customerSupport.address.postalCode,
      country: isSameSupportAddress
        ? formState.profile.business.address.country
        : formState.customerSupport.address.country
    }
  }
});

export function CompanyAddress() {
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const { account, patchAccount } = useContext(OnboardingContext);
  const { companyAddressStep, getNextStepUrl, getPreviousStepUrl } =
    useContext(OnboardingStepsContext);
  const { facilitatorID } = useContext(FacilitatorContext);
  const { formState, formFields, setFormState } = companyAddressStep;
  const { fields } = useValidatedFields(formFields);

  const [errors, setErrors] = useState<OnboardingErrors<Account>>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // On unmount, set the form state in OnboardingStepsContext to the mapped account object as it's the source of truth
    return () => {
      setFormState(mapAccountToAddressFormState(account));
    };
  }, [account, setFormState]);

  const handleBackClick = useLinkClickHandler<HTMLButtonElement>(getPreviousStepUrl());

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    const { isInvalid } = fields.validate(formFields);
    // Return early if any fields are invalid
    if (isInvalid()) return;

    if (formState.isSameSupportAddress) {
      const businessAddress = account.profile?.business?.address;
      setFormState((prev) => ({
        ...prev,
        customerSupport: {
          ...prev.customerSupport,
          address: { ...prev.customerSupport.address, ...businessAddress }
        }
      }));
    }

    setLoading(true);
    const { data, error } = await patchAccount(
      mapAddressFormStateToAccount(formState, formState.isSameSupportAddress)
    );
    setLoading(false);

    if (error) {
      setErrors(parseErrors<Account>(error));
      toast("Unable to save account. Please check the form for errors and try again.");
      return;
    }

    if (data) {
      void navigate(getNextStepUrl(), {
        state: { skipUnsavedChangesCheck: true }
      });
    }
  };

  const handleFieldValidation = (fieldName: ValidatedCompanyAddressFields) => {
    // Once a field is invalid, we re-validate input on each keystroke.
    if (fields.isInvalid(fieldName)) fields.validate(fieldName);
  };

  const handleBusinessAddressSuggestionSelected = async (suggestion: AddressSuggestion) => {
    await new Promise<void>((resolve) => {
      setFormState((prev) => {
        const newState = {
          ...prev,
          profile: {
            ...prev.profile,
            business: {
              ...prev.profile.business,
              address: {
                ...prev.profile.business.address,
                addressLine1: suggestion.addressLine1 ?? prev.profile.business.address.addressLine1,
                addressLine2: suggestion.addressLine2 ?? "",
                city: suggestion.city ?? prev.profile.business.address.city,
                postalCode: suggestion.postalCode ?? prev.profile.business.address.postalCode,
                stateOrProvince:
                  suggestion.stateOrProvince ?? prev.profile.business.address.stateOrProvince
              }
            }
          }
        };
        resolve();
        return newState;
      });
    });

    // Validate after state is updated
    fields.validate(formFields);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = fields.assertFieldName(event.target.name);
    const fieldValue = event.currentTarget.value;
    const path = fieldName.split(".") as SparsePath<CompanyAddressFormState>;

    handleFieldValidation(fieldName);
    setFormState((prev) =>
      setSparsePath(
        prev,
        path,
        fieldValue as unknown as ValueAtPath<CompanyAddressFormState, typeof path>
      )
    );
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const fieldName = fields.assertFieldName(event.target.name);
    const fieldValue = event.currentTarget.value;
    const path = fieldName.split(".") as SparsePath<CompanyAddressFormState>;

    handleFieldValidation(fieldName);
    setFormState((prev) =>
      setSparsePath(
        prev,
        path,
        fieldValue as unknown as ValueAtPath<CompanyAddressFormState, typeof path>
      )
    );
  };

  const handleCustomerSupportAddressSuggestionSelected = async (suggestion: AddressSuggestion) => {
    await new Promise<void>((resolve) => {
      setFormState((prev) => {
        const newState = {
          ...prev,
          customerSupport: {
            ...prev.customerSupport,
            address: {
              ...prev.customerSupport.address,
              addressLine1: suggestion.addressLine1 ?? prev.customerSupport.address.addressLine1,
              addressLine2: suggestion.addressLine2 ?? prev.customerSupport.address.addressLine2,
              city: suggestion.city ?? prev.customerSupport.address.city,
              postalCode: suggestion.postalCode ?? prev.customerSupport.address.postalCode,
              stateOrProvince:
                suggestion.stateOrProvince ?? prev.customerSupport.address.stateOrProvince
            }
          }
        };
        resolve();
        return newState;
      });
    });

    // Validate after state is updated
    fields.validate(formFields);
  };

  const handleAddressCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({
      ...prev,
      isSameSupportAddress: event.target.checked,
      customerSupport: event.target.checked
        ? { ...prev.customerSupport }
        : {
            ...prev.customerSupport,
            address: {
              ...prev.customerSupport.address,
              addressLine1: "",
              addressLine2: "",
              city: "",
              postalCode: "",
              stateOrProvince: ""
            }
          }
    }));
  };

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Business address</title>
      </Helmet>
      <h2 className={styles.header}>Business address</h2>
      <div className={clsx(styles.row, styles.autofill)}>
        <Icon iconComponent={IconTechnologyAndDataAi} size="M" />
        <p>We autofilled some fields based on your work email. Please check them for accuracy.</p>
      </div>
      <form onSubmit={handleSubmit} ref={formRef}>
        <header>
          <legend className={styles.fieldGroupHeading}>Legal address</legend>
          <p className={styles.fieldGroupDescription}>
            This can be found in the documents given to you when the business was formed.
          </p>
        </header>
        <FormGroup noGap={false}>
          <FloatingLabelWrapper
            as={AddressAutoComplete}
            error={handleError(errors.profile?.business?.address?.addressLine1)}
            facilitatorID={facilitatorID}
            autoComplete="none"
            label="Street address"
            name="profile.business.address.addressLine1"
            onChange={handleInputChange}
            onSuggestionSelected={handleBusinessAddressSuggestionSelected}
            required={!!formFields["profile.business.address.addressLine1"].validate}
            value={formState.profile.business.address.addressLine1 || ""}
            warning={fields.getMessageIfInvalid("profile.business.address.addressLine1")}
          />
          <FloatingLabelInput
            label="Apartment, suite, or floor"
            name="profile.business.address.addressLine2"
            onChange={handleInputChange}
            value={formState.profile.business.address.addressLine2 || ""}
            warning={handleError(errors.profile?.business?.address?.addressLine2)}
            required={!!formFields["profile.business.address.addressLine2"].validate}
          />
          <FloatingLabelInput
            error={handleError(errors.profile?.business?.address?.city)}
            label="City"
            name="profile.business.address.city"
            onChange={handleInputChange}
            required={!!formFields["profile.business.address.city"].validate}
            value={formState.profile.business.address.city || ""}
            warning={fields.getMessageIfInvalid("profile.business.address.city")}
          />
          <div className={styles.twoColumns}>
            <Select
              className={styles.stateSelect}
              error={handleError(errors.profile?.business?.address?.stateOrProvince)}
              floatingLabelStyle
              label="State"
              name="profile.business.address.stateOrProvince"
              onChange={handleSelectChange}
              placeholder="--"
              required={!!formFields["profile.business.address.stateOrProvince"].validate}
              value={formState.profile.business.address.stateOrProvince || ""}
              warning={fields.getMessageIfInvalid("profile.business.address.stateOrProvince")}
            >
              {stateOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
              <optgroup label="US outlying territories">
                {territoryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
              <optgroup label="Armed Forces">
                {armedForcesOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
            </Select>
            <FloatingLabelInput
              error={handleError(errors.profile?.business?.address?.postalCode)}
              inputMode="numeric"
              label="Zip code"
              maxLength={5}
              minLength={5}
              name="profile.business.address.postalCode"
              onBeforeInput={(e: CompositionEvent<HTMLInputElement>) => {
                if (e.data.match(/[^0-9]/)) {
                  e.preventDefault();
                }
              }}
              onChange={handleInputChange}
              pattern="[0-9]{5}"
              required={!!formFields["profile.business.address.postalCode"].validate}
              type="text"
              value={formState.profile.business.address.postalCode || ""}
              warning={fields.getMessageIfInvalid("profile.business.address.postalCode")}
            />
          </div>
        </FormGroup>

        <header>
          <legend className={styles.fieldGroupHeading}>Business address</legend>
          <p className={styles.fieldGroupDescription}>
            This address is where you conduct your day to day business.
          </p>
        </header>

        <FormGroup noGap={false}>
          <Checkbox
            checked={formState.isSameSupportAddress}
            label="My business address is the same as my legal address."
            onChange={handleAddressCheckboxChange}
          />
        </FormGroup>

        {!formState.isSameSupportAddress && (
          <FormGroup noGap={false}>
            <FloatingLabelWrapper
              as={AddressAutoComplete}
              error={handleError(errors.customerSupport?.address?.addressLine1)}
              facilitatorID={facilitatorID}
              autoComplete="none"
              label="Street address"
              name="customerSupport.address.addressLine1"
              onChange={handleInputChange}
              onSuggestionSelected={handleCustomerSupportAddressSuggestionSelected}
              required={!!formFields["customerSupport.address.addressLine1"].validate}
              value={formState.customerSupport.address.addressLine1 || ""}
              warning={fields.getMessageIfInvalid("customerSupport.address.addressLine1")}
            />
            <FloatingLabelInput
              label="Apartment, suite, or floor"
              name="customerSupport.address.addressLine2"
              onChange={handleInputChange}
              value={formState.customerSupport.address.addressLine2 || ""}
              warning={handleError(errors.customerSupport?.address?.addressLine2)}
              required={!!formFields["customerSupport.address.addressLine2"].validate}
            />
            <FloatingLabelInput
              error={handleError(errors.customerSupport?.address?.city)}
              label="City"
              name="customerSupport.address.city"
              onChange={handleInputChange}
              required={!!formFields["customerSupport.address.city"].validate}
              value={formState.customerSupport.address.city || ""}
              warning={fields.getMessageIfInvalid("customerSupport.address.city")}
            />
            <div className={styles.twoColumns}>
              <Select
                className={styles.stateSelect}
                error={handleError(errors.customerSupport?.address?.stateOrProvince)}
                floatingLabelStyle
                label="State"
                name="customerSupport.address.stateOrProvince"
                onChange={handleSelectChange}
                placeholder="--"
                required={!!formFields["customerSupport.address.stateOrProvince"].validate}
                value={formState.customerSupport.address.stateOrProvince || ""}
                warning={fields.getMessageIfInvalid("customerSupport.address.stateOrProvince")}
              >
                {stateOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                <optgroup label="US outlying territories">
                  {territoryOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="Armed Forces">
                  {armedForcesOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </optgroup>
              </Select>
              <FloatingLabelInput
                error={handleError(errors.customerSupport?.address?.postalCode)}
                inputMode="numeric"
                label="Zip code"
                maxLength={5}
                minLength={5}
                name="customerSupport.address.postalCode"
                onBeforeInput={(e: CompositionEvent<HTMLInputElement>) => {
                  if (e.data.match(/[^0-9]/)) {
                    e.preventDefault();
                  }
                }}
                onChange={handleInputChange}
                pattern="[0-9]{5}"
                required={!!formFields["customerSupport.address.postalCode"].validate}
                value={formState.customerSupport.address.postalCode || ""}
                warning={fields.getMessageIfInvalid("customerSupport.address.postalCode")}
              />
            </div>
          </FormGroup>
        )}

        <FooterButtons onBack={handleBackClick} onContinue={handleSubmit} isLoading={loading} />
      </form>
    </div>
  );
}
