import clsx from "clsx";
import { FormEventHandler, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  Button,
  CharCounterInput,
  EINInput,
  FloatingLabelInput,
  FloatingLabelWrapper,
  FormGroup,
  Icon,
  PhoneNumberInput,
  SSNInput,
  Select
} from "@moovfinancial/cargo";
import { IconTechnologyAndDataAi } from "@moovfinancial/cargo/icons";
import useValidatedFields from "@moovfinancial/cargo/src/hooks/useValidatedFields";
import { isSuccess } from "@moovfinancial/common/api/response";
import { useOpenApi } from "@moovfinancial/common/hooks/useOpenApi";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import {
  Industry,
  backupIndustryCodes,
  getCodesByTitle,
  getTitleByCodes,
  isFinancialInstitution
} from "@moovfinancial/common/utils/Industry";
import { REGEX } from "@moovfinancial/common/utils/regex";
import FloatingLabelComboBox from "components/form/FloatingLabelComboBox";
import { Checkbox } from "components/form/Form";
import { primaryRegulatorMap } from "api/v2";
import { businessTypeMap } from "api/v2/accounts.model";
import { type Account, OnboardingContext, PatchAccount } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { ValidatedCompanyInfoFields } from "contexts/OnboardingStepsContext.utils";
import { type OnboardingErrors, handleError, parseErrors } from "../helpers/errors";
import styles from "./CompanyInformation.module.scss";

type BusinessType = components["schemas"]["BusinessType"];
type PrimaryRegulator = components["schemas"]["PrimaryRegulator"];

export interface CompanyInfoFormState {
  businessType: BusinessType | "";
  description: string;
  doingBusinessAs: string;
  ein: string;
  email: string;
  industryCodes: {
    mcc: string;
    naics: string;
    sic: string;
  };
  legalBusinessName: string;
  phone: string;
  taxIDProvided: boolean;
  website: string;
  primaryRegulator?: PrimaryRegulator | "";
  isDBASelected: boolean;
  EINorSSN: "EIN" | "SSN";
}

export const mapAccountToCompanyInfoFormState = (
  account: Partial<Account>
): CompanyInfoFormState => ({
  businessType: account.profile?.business?.businessType ?? "",
  description: account.profile?.business?.description ?? "",
  doingBusinessAs: account.profile?.business?.doingBusinessAs ?? "",
  ein: account.profile?.business?.taxID?.ein?.number ?? "",
  email: account.profile?.business?.email ?? "",
  industryCodes: {
    mcc: account.profile?.business?.industryCodes?.mcc ?? "",
    naics: account.profile?.business?.industryCodes?.naics ?? "",
    sic: account.profile?.business?.industryCodes?.sic ?? ""
  },
  legalBusinessName: account.profile?.business?.legalBusinessName ?? "",
  phone: account.profile?.business?.phone?.number ?? "",
  taxIDProvided: account.profile?.business?.taxIDProvided ?? false,
  website: account.profile?.business?.website ?? "",
  primaryRegulator: account.profile?.business?.primaryRegulator ?? "",
  isDBASelected: !!account.profile?.business?.doingBusinessAs,
  EINorSSN: "EIN"
});

export const mapCompanyInfoFormStateToAccount = (
  formState: CompanyInfoFormState
): PatchAccount => ({
  profile: {
    business: {
      businessType: formState.businessType || undefined,
      description: formState.description,
      doingBusinessAs: formState.doingBusinessAs,
      taxID: { ein: { number: formState.ein } },
      email: formState.email,
      industryCodes: formState.industryCodes,
      legalBusinessName: formState.legalBusinessName,
      phone: {
        number: formState.phone
      },
      /* @ts-expect-error - openApiSpecIsWrong - This field should exist on the PatchAccount type */
      taxIDProvided: formState.taxIDProvided,
      website: formState.website,
      primaryRegulator: formState.primaryRegulator || undefined
    }
  }
});

export function CompanyInformation() {
  const { account, patchAccount } = useContext(OnboardingContext);
  const { companyInfoStep, getNextStepUrl, renderFormValidated, setRenderFormValidated } =
    useContext(OnboardingStepsContext);
  const { formState, setFormState, formFields } = companyInfoStep;
  const { fields } = useValidatedFields(formFields);
  const { openApi } = useOpenApi();

  const [errors, setErrors] = useState<OnboardingErrors<Account>>({});
  const [industries, setIndustries] = useState<Industry[]>(backupIndustryCodes);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const descriptionRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    // Sets the ref for the description field so we can validate it with useValidatedFields
    fields.setRef("description", descriptionRef);
    if (renderFormValidated) {
      fields.validate(formFields);
      setRenderFormValidated(false);
    }

    async function getIndustryCodes() {
      const { response, data } = await openApi.GET("/industries");
      if (isSuccess(response) && data?.industries) setIndustries(data.industries);
    }
    void getIndustryCodes();
  }, []);

  useEffect(() => {
    // On unmount, set the form state in OnboardingStepsContext to the mapped account object as it's the source of truth
    return () => {
      setFormState(mapAccountToCompanyInfoFormState(account));
    };
  }, [account, setFormState]);

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    const { isInvalid } = fields.validate(formFields);
    // Return early if any fields are invalid
    if (isInvalid()) return;

    setLoading(true);
    const apiResponse = await patchAccount(mapCompanyInfoFormStateToAccount(formState));
    setLoading(false);

    if (apiResponse.error) {
      setErrors(parseErrors<Account>(apiResponse.error));
      toast("Unable to save account. Please check the form for errors and try again.");
      return;
    }

    if (apiResponse.data) {
      void navigate(getNextStepUrl(), {
        state: { skipUnsavedChangesCheck: true }
      });
    }
  };

  const handleInputValidation = (fieldName: ValidatedCompanyInfoFields) => {
    if (fields.isInvalid(fieldName)) {
      fields.validate({
        [fieldName]: {
          basicValidation: false
        }
      });
    }
  };

  const handleStandardInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = fields.assertFieldName(e.currentTarget.name);
    const fieldValue = e.currentTarget.value;
    handleInputValidation(fieldName);
    setFormState((prev) => ({ ...prev, [fieldName]: fieldValue }));
  };

  const handleCustomValueInputChange = (fieldName: ValidatedCompanyInfoFields, value: string) => {
    handleInputValidation(fieldName);
    setFormState((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handlePrimaryRegulatorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const fieldName = fields.assertFieldName(e.currentTarget.name);
    const fieldValue = e.currentTarget.value;
    handleInputValidation(fieldName);
    setFormState((prev) => ({ ...prev, primaryRegulator: fieldValue as PrimaryRegulator }));
  };

  const handleDBAChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({
      ...prev,
      isDBASelected: e.target.checked,
      doingBusinessAs: e.target.checked ? prev.doingBusinessAs : ""
    }));
  };

  const handleIndustryCodesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const industryValue = e.target.value;
    const codes = getCodesByTitle(industryValue, industries);

    if (codes) {
      const { mcc, naics, sic } = codes;
      setFormState((prev) => ({
        ...prev,
        industryCodes: { mcc: mcc ?? "", naics: naics ?? "", sic: sic ?? "" }
      }));
    } else {
      setFormState((prev) => ({
        ...prev,
        industryCodes: { mcc: "", naics: "", sic: "" }
      }));
    }
  };

  const handleTaxIdTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormState((prev) => ({ ...prev, EINorSSN: e.target.value as "EIN" | "SSN" }));
  };

  const handleBusinessTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const businessType = e.target.value as BusinessType;

    setFormState((prev) => ({
      ...prev,
      businessType,
      // Only sole proprietorships can use SSNs as EINs, so we reset this to EIN to ensure it's the selected value whenever the Tax ID field is locked.
      EINorSSN: businessType === "soleProprietorship" ? prev.EINorSSN : "EIN"
    }));
  };

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Business information</title>
      </Helmet>
      <h2 className={styles.header}>Business information</h2>
      <div className={clsx(styles.row, styles.autofill)}>
        <Icon iconComponent={IconTechnologyAndDataAi} size="M" />
        <p>We autofilled some fields based on your work email. Please check them for accuracy.</p>
      </div>
      <form onSubmit={handleSubmit} ref={formRef}>
        <FormGroup noGap={false}>
          <FloatingLabelInput
            error={handleError(errors.profile?.business?.legalBusinessName)}
            label="Legal business name"
            name="legalBusinessName"
            onChange={handleStandardInputChange}
            value={formState.legalBusinessName}
            warning={fields.getMessageIfInvalid("legalBusinessName")}
          />
        </FormGroup>
        <FormGroup noGap={false}>
          <Checkbox
            checked={formState.isDBASelected}
            label="I use a registered DBA to conduct business."
            onChange={handleDBAChange}
          />
        </FormGroup>
        {formState.isDBASelected && (
          <FormGroup noGap={false}>
            <FloatingLabelInput
              error={handleError(errors.profile?.business?.doingBusinessAs)}
              label="Doing business as"
              name="doingBusinessAs"
              onChange={handleStandardInputChange}
              value={formState.doingBusinessAs}
              warning={fields.getMessageIfInvalid("doingBusinessAs")}
            />
          </FormGroup>
        )}
        <FormGroup noGap={false}>
          <FloatingLabelComboBox
            autoComplete="off"
            className={styles.industry}
            error={handleError(
              errors.profile?.business?.industryCodes?.mcc ||
                errors.profile?.business?.industryCodes?.naics ||
                errors.profile?.business?.industryCodes?.sic
            )}
            data={industries.map((code) => ({
              value: code.title ?? "",
              label: `${code.mcc}`
            }))}
            format="both"
            label="Industry"
            name="industryCodes"
            noMargins
            onChange={handleIndustryCodesChange}
            value={getTitleByCodes(formState.industryCodes, industries)}
            warn={!!fields.getMessageIfInvalid("industryCodes")}
          />
        </FormGroup>
        {isFinancialInstitution(formState.industryCodes) && (
          <FormGroup noGap={false}>
            <Select
              error={handleError(errors.profile?.business?.primaryRegulator)}
              floatingLabelStyle
              label="Primary regulator"
              name="primaryRegulator"
              onChange={handlePrimaryRegulatorChange}
              placeholder="--"
              value={formState.primaryRegulator}
              warning={fields.getMessageIfInvalid("primaryRegulator")}
            >
              <hr />
              {Object.entries(primaryRegulatorMap).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
          </FormGroup>
        )}
        <FormGroup noGap={false}>
          <Select
            error={handleError(errors.profile?.business?.businessType)}
            floatingLabelStyle
            label="Business type"
            name="businessType"
            onChange={handleBusinessTypeChange}
            placeholder="--"
            warning={fields.getMessageIfInvalid("businessType")}
            value={formState.businessType ?? ""}
          >
            <hr />
            {Object.entries(businessTypeMap).map(([key, value]) => (
              <option key={key + value} value={key}>
                {value}
              </option>
            ))}
          </Select>
        </FormGroup>
        <div className={styles.row}>
          <FormGroup noGap={false}>
            <Select
              floatingLabelStyle
              label="Tax ID type"
              onChange={handleTaxIdTypeChange}
              value={formState.EINorSSN}
              isLocked={formState.businessType !== "soleProprietorship"}
              defaultValue={"EIN"}
            >
              <option value="EIN">EIN</option>
              {/* Only sole proprietorships can use SSNs as EINs, see https://docs.moov.io/api/moov-accounts/accounts/patch/#body */}
              {formState.businessType === "soleProprietorship" && (
                <option value="SSN">SSN/ITIN</option>
              )}
            </Select>
          </FormGroup>
          <FormGroup noGap={false}>
            {formState.EINorSSN === "SSN" ? (
              <FloatingLabelWrapper
                as={SSNInput}
                error={handleError(errors.profile?.business?.taxID?.ein?.number)}
                forceFloating={formState.taxIDProvided}
                label="Tax ID"
                name="ein"
                // For sole proprietors, an SSN can be used as an EIN, so we use the same field for both.
                // Source: https://docs.moov.io/api/moov-accounts/accounts/patch/#body
                onValueChange={(ssn: string) => handleCustomValueInputChange("ein", ssn)}
                pattern={REGEX.SSN}
                placeholder={formState.taxIDProvided ? "•••-••-••••" : undefined}
                value={formState.ein}
                warning={fields.getMessageIfInvalid("ein")}
              />
            ) : (
              <FloatingLabelWrapper
                as={EINInput}
                error={handleError(errors.profile?.business?.taxID?.ein?.number)}
                forceFloating={formState.taxIDProvided}
                label="Tax ID"
                name="ein"
                onValueChange={(ein: string) => handleCustomValueInputChange("ein", ein)}
                pattern={REGEX.EIN}
                placeholder={formState.taxIDProvided ? "••-•••••••" : undefined}
                value={formState.ein}
                warning={fields.getMessageIfInvalid("ein")}
              />
            )}
          </FormGroup>
        </div>
        <FormGroup noGap={false}>
          <FloatingLabelInput
            autoComplete="email work"
            error={handleError(errors.profile?.business?.email)}
            label="Business email"
            name="email"
            onChange={handleStandardInputChange}
            pattern={REGEX.EMAIL}
            type="email"
            value={formState.email}
            warning={fields.getMessageIfInvalid("email")}
          />
        </FormGroup>
        <FormGroup noGap={false}>
          <FloatingLabelWrapper
            as={PhoneNumberInput}
            error={handleError(errors.profile?.business?.phone?.number)}
            label="Phone number"
            name="phone"
            onValueChange={(phone: string) => handleCustomValueInputChange("phone", phone)}
            value={formState.phone}
            warning={fields.getMessageIfInvalid("phone")}
          />
        </FormGroup>
        <FormGroup noGap={false}>
          <FloatingLabelInput
            error={handleError(errors.profile?.business?.website)}
            label="Company website"
            name="website"
            onChange={handleStandardInputChange}
            pattern={REGEX.URL}
            placeholder="https://"
            value={formState.website}
            warning={fields.getMessageIfInvalid("website")}
          />
        </FormGroup>
        <FormGroup noGap={false}>
          <FloatingLabelWrapper
            as={CharCounterInput}
            error={handleError(errors.profile?.business?.description)}
            helper="100 character limit"
            label="Tell us about what your business does"
            maxLength={100}
            minLength={10}
            name="description"
            onChange={handleStandardInputChange}
            ref={descriptionRef}
            value={formState.description}
            warning={fields.getMessageIfInvalid("description")}
          />
        </FormGroup>
        <Button
          buttonSize="M"
          buttonStyle="fill"
          buttonType="primary"
          isLoading={loading}
          type="submit"
        >
          Save & continue
        </Button>
      </form>
    </div>
  );
}
