import clsx from "clsx";
import { ChangeEvent, type JSX, type ReactNode, memo, useMemo } from "react";
import type { Optional } from "@moovfinancial/common/types/Optional";
import { Theme } from "@moovfinancial/common/types/Theme";
import { components } from "@moovfinancial/common/types/__generated-types__/api";
import { ToggleComponents } from "../../../../Foundations/ToggleComponents";
import { FormGroup } from "../../../FormGroup";
import { type FloatingLabelInputProps, FloatingLabelWrapper } from "../../FloatingLabelInput";
import { SSNInput, type SSNInputProps } from "./SSNInput";
import styles from "./GovernmentIDInput.module.scss";

export type GovernmentID = Exclude<components["schemas"]["GovernmentID"], null | undefined>;

interface GovernmentIDInputBaseProps {
  onChange: (id: GovernmentID) => void;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  theme?: Theme<typeof styles>;
  value: GovernmentID | undefined;
  warning?: string | ReactNode;
  error?: string | ReactNode;
}

type GovernmentIDInputWithFloatingLabelsProps = Optional<
  Omit<FloatingLabelInputProps, "as" | "onChange" | "value">,
  "label"
> &
  GovernmentIDInputBaseProps & {
    hasFloatingLabels?: true;
  };

type GovernmentIDInputWithoutFloatingLabelsProps = Omit<SSNInputProps, "onChange" | "value"> &
  GovernmentIDInputBaseProps & { hasFloatingLabels: false };

export type GovernmentIDInputProps =
  | GovernmentIDInputWithFloatingLabelsProps
  | GovernmentIDInputWithoutFloatingLabelsProps;

function GovernmentIDInputInner(props: GovernmentIDInputWithFloatingLabelsProps): JSX.Element;
function GovernmentIDInputInner(props: GovernmentIDInputWithoutFloatingLabelsProps): JSX.Element;
function GovernmentIDInputInner(props: GovernmentIDInputProps): JSX.Element;
function GovernmentIDInputInner({
  hasFloatingLabels = true,
  label,
  onChange,
  onInputChange,
  theme,
  value,
  warning,
  error,
  ...rest
}: GovernmentIDInputProps) {
  // not worth to memoize/useCallback as they will be recreated on every keystroke anyways
  const handleSSNChange = (ssn: string) => {
    onChange({
      ...value,
      ssn: { ...value?.ssn, full: ssn },
      itin: undefined
    });
  };

  const handleITINChange = (itin: string) => {
    onChange({
      ...value,
      itin: { ...value?.itin, full: itin },
      ssn: undefined
    });
  };

  const ITINComponent = useMemo(
    () =>
      hasFloatingLabels ? (
        <FloatingLabelWrapper
          as={SSNInput}
          label={label ?? "ITIN"}
          onChange={onInputChange}
          onValueChange={handleITINChange}
          value={value?.itin?.full ?? ""}
          isWarning={!!warning}
          isErroring={!!error}
          {...rest}
        />
      ) : (
        <SSNInput
          label={label ?? "ITIN"}
          onChange={onInputChange}
          onValueChange={handleITINChange}
          value={value?.itin?.full ?? ""}
          isWarning={!!warning}
          isErroring={!!error}
          {...rest}
        />
      ),
    [value?.itin?.full, handleITINChange, hasFloatingLabels, label, onInputChange, rest]
  );

  const SSNComponent = useMemo(
    () =>
      hasFloatingLabels ? (
        <FloatingLabelWrapper
          as={SSNInput}
          label={label ?? "SSN"}
          onChange={onInputChange}
          onValueChange={handleSSNChange}
          value={value?.ssn?.full ?? ""}
          isWarning={!!warning}
          isErroring={!!error}
          {...rest}
        />
      ) : (
        <SSNInput
          label={label ?? "SSN"}
          onChange={onInputChange}
          onValueChange={handleSSNChange}
          value={value?.ssn?.full ?? ""}
          isWarning={!!warning}
          isErroring={!!error}
          {...rest}
        />
      ),
    [value?.ssn?.full, handleSSNChange, hasFloatingLabels, label, onInputChange, rest]
  );

  return (
    <FormGroup warningMessage={warning} errorMessage={error}>
      <ToggleComponents
        primaryComponent={SSNComponent}
        secondaryComponent={ITINComponent}
        switchToPrimaryButtonLabel="Use SSN"
        switchToSecondaryButtonLabel="Use ITIN"
        theme={{
          button: hasFloatingLabels
            ? clsx(styles.buttonForFloatingLabels, theme?.buttonForFloatingLabels)
            : undefined,
          container: clsx(styles.container, theme?.container)
        }}
      />
    </FormGroup>
  );
}

export const GovernmentIDInput = memo(GovernmentIDInputInner);
