import clsx from "clsx";
import { HTMLAttributes, useState } from "react";
import { toast } from "react-toastify";
import { Sizes } from "@moovfinancial/common/styling/StandardSizes";
import Tippy from "@tippyjs/react";
import { IconCopy } from "../../Icons";
import { Icon } from "../../Icons/Icon";
import styles from "./ButtonCopy.module.scss";

interface ButtonCopyProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Aria label for screen readers. Optional and defaults to "Copy".
   */
  ariaLabel?: string;
  /**
   * The content saved to the user's clipboard on click.
   */
  copyContent: string;
  /**
   * The content displayed beside the copy button.
   */
  children?: React.ReactNode;
  /**
   * Places copy icon to the left or the right of the children
   */
  iconPlacement?: "left" | "right";
  /**
   * Sizes the copy icon using Icon Sizes.
   */
  iconSize?: Sizes;
  /**
   * Should we hide the icon altogether?
   */
  hideIcon?: boolean;
  /**
   * Should we show a toast when the user copies the content?
   */
  withToast?: boolean;
  /**
   * Should the contents of the copy button be aligned right?
   */
  alignRight?: boolean;
  /**
   * Determines ellipsis placement. Requires children to be a string.
   */
  truncate?: "right" | "middle" | "none";
  /**
   * Optional additional classname for the component.
   */
  className?: string;
}

const truncateMid = (text: string) => {
  if (text.length <= 9) return text;
  const beginning = text.slice(0, 5);
  const end = text.slice(text.length - 4, text.length);
  return `${beginning}...${end}`;
};

/**
 * ButtonCopy is a component that wraps text content that users can copy to the clipboard.
 */
export const ButtonCopy = ({
  ariaLabel = "Copy",
  copyContent,
  children,
  iconSize = "XS",
  iconPlacement = "left",
  hideIcon = false,
  withToast = false,
  alignRight = false,
  truncate = "right",
  className,
  ...rest
}: ButtonCopyProps) => {
  const [copied, setCopied] = useState<boolean>(false);
  const handleCopy = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    await navigator.clipboard.writeText(copyContent || "").then(() => {
      setCopied(true);
      if (withToast) toast.success("Copied to clipboard");
      window.setTimeout(() => setCopied(false), 2000);
    });
  };

  const copyIcon = <Icon iconComponent={IconCopy} size={iconSize} />;

  return (
    <div
      role="button"
      aria-label={ariaLabel}
      className={clsx(styles.copy, className)}
      onClick={handleCopy}
      {...rest}
    >
      <Tippy
        placement="top"
        content={copied ? "Copied!" : "Click to copy"}
        duration={[100, 500]}
        hideOnClick={false}
        appendTo="parent"
      >
        <div
          className={clsx(styles.copyButton, alignRight && styles.alignRight)}
          data-testid="copy-tippy"
        >
          {iconPlacement === "left" && !hideIcon && copyIcon}
          <div className={clsx(truncate === "right" && styles.ellipsis)}>
            {truncate === "middle" && typeof children === "string"
              ? truncateMid(children)
              : children}
          </div>
          {iconPlacement === "right" && !hideIcon && copyIcon}
        </div>
      </Tippy>
    </div>
  );
};
