import { clone, setPath } from "remeda";

/**
 * Original Remeda types that caltulate the available paths for an object given a prefix
 */

export type SparsePath<T, Prefix extends ReadonlyArray<unknown> = []> =
  T extends ReadonlyArray<unknown>
    ? SparsePath<T[number], [...Prefix, number]> | Prefix
    : T extends Record<PropertyKey, unknown>
      ? PathsOfObject<T, Prefix> | Prefix
      : Prefix;

type PathsOfObject<T, Prefix extends ReadonlyArray<unknown>> = {
  [K in keyof T]-?: SparsePath<T[K], readonly [...Prefix, K]>;
}[keyof T];

/**
 * Get the value at a given path, but only if the path is valid for the given object
 *
 * Deals correctly with deeply partial objects by making the props `Required<...>`
 *
 * e.g.
 *
 * type Obj = { // deeply partial object
 *   a?: {
 *     b?: {
 *       c?: number;
 *     };
 *   };
 *   z?: {
 *     x?: boolean;
 *   };
 * };
 *
 * const path1 = ["z", "x"] as const;
 * const path2 = ["a", "b", "c"] as const;
 * type VAP1 = ValueAtPath<Obj, typeof path1>; // boolean
 * type VAP2 = ValueAtPath<Obj, typeof path2>; // number
 *
 */
export type ValueAtPath<T, TPath> = TPath extends readonly [infer Head, ...infer Rest]
  ? Head extends keyof Required<T>
    ? ValueAtPath<Required<T>[Head], Rest>
    : never
  : T;

/**
 * Generic that marks the passed `Path` array of keys as guaranteed to exist on the passed object T
 *
 * e.g.
 *
 * type Obj = {
 *   a?: {
 *     b?: {
 *       c?: number;
 *     };
 *   };
 * };
 * const obj: Obj = {};
 * const newObj = setSparsePath(obj, ["a", "b", "c"], 2);
 * const test = newObj.a.b.c; // number instead of number?
 *
 */

type ObjectWithGuaranteedPath<T, Path extends ReadonlyArray<unknown> = []> = Path extends readonly [
  infer Head,
  ...infer Rest
]
  ? Head extends keyof T
    ? // Mark the prop in the Path as required at this level and recurse
      {
        [K in Head]: ObjectWithGuaranteedPath<Required<T>[K], Rest>;
      } & {
        [K in Exclude<keyof T, Head>]: T[K];
      }
    : T
  : T; // base case, leaf value in the object

/**
 * Function to set a value at a given path in an object. If the nested object(s) do not exist, they will be created.
 *
 * The types will guarantee that the just set path exists on the returned object.
 *
 * Basically equivalend to Remeda's `setPath`, but supporting passing in a sparse objects as input.
 *
 * It will always return a new object, even if the resulting object contains the same values as the original object
 *
 * @example
 *
 * const obj = {};
 * const newObj = setPath(obj, ["a", "b", "c"] as const, 2); // 🔴 NOTE THAT THE "as const" IS REQUIRED HERE
 * console.log(newObj); // { a: { b: { c: 2 } } }
 * console.log(newObj === obj); // false
 * typeof newObj.a.b.c; // number
 */
export const setSparsePath = <T, TPath extends SparsePath<T>, Value extends ValueAtPath<T, TPath>>(
  input: T,
  path: TPath,
  value: Value
): ObjectWithGuaranteedPath<T, typeof path> => {
  // Very nasty type stuff, but at least it's wholly contained in this function instead of spreaded all over the codebase
  // and the function returns the correct types, which is the most important thing
  const obj = clone(input);
  // Make sure all the nested objects exist first, as Remeda does not support sparse paths
  // https://github.com/remeda/remeda/issues/554
  let current = obj as Record<PropertyKey, unknown>;
  path.forEach((key) => {
    current[key as string] = current[key as string] || {};
    current = current[key as string] as Record<PropertyKey, unknown>;
  });
  const result = setPath(obj, path, value);
  return result as ObjectWithGuaranteedPath<T, typeof path>;
};
