import clsx from "clsx";
import { useState } from "react";
import { Theme } from "@moovfinancial/common/types/Theme";
import type { components } from "@moovfinancial/common/types/__generated-types__/api";
import { Category } from "./Category";
import { CategoryLinks } from "./CategoryLinks";
import { FeeRow } from "./FeeRow";
import { PricingMetadataCard } from "./PricingMetadataCard";
import styles from "./Pricing.module.scss";

type FeePlan = components["schemas"]["FeePlan"];
type Fee = components["schemas"]["Fee"];
type Agreement = components["schemas"]["Agreement"];
type PartnerPricingAgreement = components["schemas"]["PartnerPricingAgreement"];
type DisplayCategory =
  | "Card acquiring"
  | "Instant payments"
  | "Other card fees"
  | "ACH"
  | "Platform & other fees";
type CategoryRecord = Partial<Record<DisplayCategory, Fee[]>>;

/**
 * Order of appearance for display categories
 */
const categoryOrder: DisplayCategory[] = [
  "Card acquiring",
  "Instant payments",
  "Other card fees",
  "ACH",
  "Platform & other fees"
];

/**
 * Sorts backend fees into frontend categories
 * Mapping is defined in https://docs.google.com/spreadsheets/d/1kjU6mr-nnt7oompuQBhutIfn6QbYiNE3J5x4xoCIatE/edit?pli=1&gid=1316557581#gid=1316557581
 */
const feeToDisplayCategory = (fee: Fee): DisplayCategory => {
  switch (fee.feeCategory) {
    case "card-acquiring":
      return "Card acquiring";
    case "card-pull":
    case "card-push":
    case "rtp":
      return "Instant payments";
    case "card-other":
      return "Other card fees";
    case "ach":
      return "ACH";
    case "network-passthrough":
    case "other":
      return "Platform & other fees";
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default:
      return "Platform & other fees";
  }
};

/**
 * Maps display categories to matching descriptions
 * Mapping is defined in https://www.figma.com/design/cJbcP9VR5rmMzyN601Yvr2/Billing?node-id=9799-92720&node-type=frame&t=hnJuckaJZDo8J16P-0
 */
const displayCategoryToDescription = (category: DisplayCategory) => {
  switch (category) {
    case "Card acquiring":
      return "Fees assessed on each card transaction processed by the card networks.";
    case "Instant payments":
      return "Fees assessed when processing transactions via instant or real-time payment networks.";
    case "Other card fees":
      return "Fees for various services related to processing, verification, fraud prevention, and card transaction management.";
    case "ACH":
      return "Fees assessed on each ACH transaction that is processed or returned.";
    case "Platform & other fees":
      return "Fees assessed on a recurring basis for the maintenance and usage of the platform.";
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default:
      return "";
  }
};

/**
 * Helper function to sort fees within a category
 */
const compareFees = (a: Fee, b: Fee) => {
  const aHasVolume = a.billableEvent?.toLowerCase().includes("volume") || false;
  const bHasVolume = b.billableEvent?.toLowerCase().includes("volume") || false;

  if (aHasVolume && !bHasVolume) return -1;
  if (!aHasVolume && bHasVolume) return 1;

  return (a.feeName || "").localeCompare(b.feeName || "");
};

interface PricingProps {
  /** Array of fee plans to display */
  feePlans?: FeePlan[];
  /** The fee plan agreement to display within the PricingMetadataCard */
  feePlanAgreement?: Agreement | PartnerPricingAgreement;
  /** Whether to show descriptions for each category */
  showDescriptions?: boolean;
  /** Determines the number of columns displayed on larger screens */
  responsive?: boolean;
  /** Whether to show links that scroll to each category, and which screen size to show them on */
  categoryLinkDisplay?: "mobile" | "desktop" | "both";
  /** Whether the categories are collapsible */
  collapsible?: boolean;
  /** Whether only one category should be expanded at a time */
  expandOneAtATime?: boolean;
  /** Theme overrides to restyle the component */
  theme?: Theme<typeof styles>;
}

/**
 * Very specialized component that displays Billing Plans pricing information
 */
export function Pricing({
  feePlans,
  feePlanAgreement,
  showDescriptions = false,
  responsive = true,
  categoryLinkDisplay,
  collapsible = false,
  expandOneAtATime = false,
  theme
}: PricingProps) {
  // Organize fees into categories and subcategories
  const categorizedFees: CategoryRecord = {};
  feePlans?.forEach((plan) => {
    plan.billableFees?.forEach((fee) => {
      const category = feeToDisplayCategory(fee);
      (categorizedFees[category] ||= []).push(fee);
    });
  });
  const visibleCategoryOrder = categoryOrder.filter((category) => categorizedFees[category]);

  // Track expanded categories
  const [expandedCategories, setExpandedCategories] = useState<Record<DisplayCategory, boolean>>({
    "Card acquiring": expandOneAtATime ? visibleCategoryOrder[0] === "Card acquiring" : true,
    "Instant payments": expandOneAtATime ? visibleCategoryOrder[0] === "Instant payments" : true,
    "Other card fees": expandOneAtATime ? visibleCategoryOrder[0] === "Other card fees" : true,
    ACH: expandOneAtATime ? visibleCategoryOrder[0] === "ACH" : true,
    "Platform & other fees": expandOneAtATime
      ? visibleCategoryOrder[0] === "Platform & other fees"
      : true
  });

  // Collapses or expands clicked category
  const handleCollapseButtonClick = (category: DisplayCategory) => {
    const newExpandedState = !expandedCategories[category];
    if (expandOneAtATime)
      Object.keys(expandedCategories).forEach(
        (key) => (expandedCategories[key as DisplayCategory] = false)
      );
    expandedCategories[category] = newExpandedState;
    setExpandedCategories({ ...expandedCategories });
  };

  return feePlans && feePlans.length > 0 ? (
    <div className={styles.pricing}>
      {!!feePlanAgreement && (
        <PricingMetadataCard
          feePlan={feePlans[0]}
          feePlanAgreement={feePlanAgreement}
          className={styles.pricingMetadataCard}
        />
      )}
      {!!categoryLinkDisplay && (
        <CategoryLinks
          categories={visibleCategoryOrder}
          display={categoryLinkDisplay}
          theme={{
            ...theme,
            categoryLinks: clsx(styles.categoryLinks, theme?.categoryLinks)
          }}
        />
      )}
      <div className={styles.categories}>
        {visibleCategoryOrder.map((category) => (
          <Category
            key={category}
            title={category}
            description={showDescriptions ? displayCategoryToDescription(category) : ""}
            responsive={responsive}
            expanded={collapsible && expandedCategories[category]}
            collapsed={collapsible && !expandedCategories[category]}
            onCollapseButtonClick={() => handleCollapseButtonClick(category)}
          >
            {(categorizedFees[category] ||= []).sort(compareFees).map((fee) => (
              <FeeRow key={fee.billableFeeID} fee={fee} feePlan={feePlans[0]} />
            ))}
          </Category>
        ))}
      </div>
    </div>
  ) : null;
}
