import clsx from "clsx";
import { useRef } from "react";
import { useResizeObserver } from "@moovfinancial/common/hooks/animation/useResizeObserver";
import styles from "./AnimateHeight.module.scss";

type AnimateHeightProps = React.HTMLAttributes<HTMLDivElement> & {
  classWhileAnimating?: string;
  /**
   * Duration of animation in ms
   */
  duration?: number;
};

export const AnimateHeight = ({
  className,
  children,
  style,
  duration,
  classWhileAnimating,
  ...rest
}: AnimateHeightProps) => {
  const content = useRef(null);
  const { size, isAnimating } = useResizeObserver(content, duration);

  return (
    <div
      className={clsx(
        styles.animateContainer,
        className,
        isAnimating && styles.defaultWhileAnimating,
        classWhileAnimating && isAnimating && classWhileAnimating
      )}
      style={{
        height: `${size?.height ?? 0}px`,
        transition: `height ${duration ?? 400}ms ease-in-out`,
        ...style
      }}
      {...rest}
    >
      <div ref={content}>{children}</div>
    </div>
  );
};
