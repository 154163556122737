export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 *  Turns a string in camelCase into "Camel case"
 *
 *  Note that it capitalizes the first character too
 *
 */
export const camelCaseWordToSentence = (value: string) => {
  const res = value.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
  return capitalize(res);
};

/**
 *  Turns a sentence string into a camelCase word
 *
 *  Note that it lowercases the first character
 *
 */
export const sentenceToCamelCaseWord = (value: string) => {
  return value
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
      index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    )
    .replace(/\s+/g, "");
};

/**
 * Turns a dot.separated.string.word into a capitalized sentence
 *
 * it also de-camel-cases the individual words and turns them into a capitalized sub-sentence
 *
 * E.g.
 *
 * input: "moov.frontEnd.dashboard"
 * output: "Moov front end dashboard"
 *
 */
export const dotSeparatedStringToSentence = (value: string) => {
  // split by dot and space
  const words = value.split(/[. ]/g);
  const deCamelcasedWords = words.map((word) => camelCaseWordToSentence(word));
  const sentence = deCamelcasedWords.join(" ").toLowerCase();
  return capitalize(sentence);
};

/**
 * Simply turn "failed-automatic-verification" into "failed automatic verification"
 */
export const humanReadableErrorCode = (errorCode: string) => {
  return errorCode.replace(/-/g, " ");
};
