import { Button, Modal } from "@moovfinancial/cargo";
import styles from "./UnsavedChangesModal.module.scss";

export interface UnsavedChangesModalProps {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

export const UnsavedChangesModal = ({
  isOpen,
  onClose,
  onConfirm,
  children = "Are you sure you want to leave this page? Changes you made will not be saved.",
  cancelButtonText = "Cancel",
  confirmButtonText = "Leave without saving"
}: UnsavedChangesModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} data-testid="unsavedChangesModal">
      <Modal.Header title="Unsaved changes" />
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <div className={styles.buttons}>
          <Button buttonType="secondary" onClick={onClose}>
            {cancelButtonText}
          </Button>
          <Button onClick={onConfirm}>{confirmButtonText}</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
